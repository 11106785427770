import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  margin-top: 10px;
`;

export const SelectContainer = styled.div`
  flex: 15;
  text-align: left;
  margin-right: 10px;
`;

export const LoadButtonContainer = styled.div`
  flex: 5;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  white-space: nowrap;
`;
